<template>
  <div>
    <h1 style="margin-left:4%;margin-bottom:4%">Dossier requis</h1>
    <a-row type="flex" justify="center" class="table_head">
      <a-col :span="8">
        <a-button class="editable-add-btn" type="primary" @click="handleAdd"
          >Nouveau document</a-button
        >
      </a-col>
      <a-col class="text-end" :span="6">
        <search-component
          v-on:change="searchDossierRequi($event)"
        ></search-component>
      </a-col>
    </a-row>

    <a-row type="flex" justify="center">
      <a-col :span="14">
        <a-spin :spinning="processing">
          <a-table
            bordered
            v-if="dataSource.length"
            :dataSource="dataSource"
            :columns="columns"
            :rowKey="
              (record) => {
                return record.id;
              }
            "
          >
            <template slot="row_index" slot-scope="text, record, index">
              <p>{{ index + 1 }}</p>
            </template>
            <template slot="nom_fr" slot-scope="text, record">
              <editable-cell
                :text="text"
                @change="onCellChange(record.id, 'nom_fr', $event)"
              />
            </template>
            <template slot="isRequired" slot-scope="text, record">
              <a-checkbox
                :checked="isChecked(record.is_obligatoire)"
                @change="onRequiredChange($event, record)"
                >{{ record.is_obligatoire ? "Oui" : "Non" }}</a-checkbox
              >
            </template>
            <template slot="operation" slot-scope="text, record">
              <a-popconfirm
                v-if="dataSource.length"
                :title="SUPPRIMER_LIGNE"
                @confirm="() => onDelete(record)"
              >
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>Suppression</span>
                  </template>
                  <a-icon
                    type="delete"
                    class="action_icons"
                    theme="twoTone"
                    twoToneColor="#eb2f96"
                  />
                </a-tooltip>
              </a-popconfirm>
            </template>
            <template slot="etat" slot-scope="text, record">
              <a-icon
                type="check-circle"
                theme="twoTone"
                twoToneColor="#52c41a"
              />
            </template>
          </a-table>
          <EmptyResult v-else />
        </a-spin>
      </a-col>
    </a-row>
  </div>
</template>
<script>
  import EditableCell from "@/components/common/EditableCell";
  import {mapActions, mapState} from "vuex";
  import SearchComponent from "@/components/common/Search";
  import EmptyResult from "@/components/common/EmptyResult";
  import constants from "@/const/const";

  const columns = [
    {
      title: "N°",
      scopedSlots: {customRender: "row_index"},
    },
    {
      title: "Nom Document",
      dataIndex: "nom_fr",
      scopedSlots: {customRender: "nom_fr"},
    },
    {
    title: "Obligatoire",
    dataIndex: "is_obligatoire",
    scopedSlots: { customRender: "isRequired" },
  },
  {
    title: "Action",
    scopedSlots: { customRender: "operation" },
  },
];

export default {
  name: "DossiersRequisIndex",
  created() {
    this.fetchData();
  },
  components: {
    EditableCell,
    SearchComponent,
    EmptyResult,
  },
  data() {
    return {
      processing: true,
      columns,
      SUPPRIMER_LIGNE: constants.SUPPRIMER_LIGNE,
    };
  },
  computed: {
    ...mapState({
      dataSource: (state) => state.dossiers_requis.dossiers_requis,
    }),
  },
  methods: {
    fetchData() {
      this.startProcessing();
      this.fetchDossiersRequis()
              .finally(() => this.stopProcessing());
    },
    onRequiredChange(e, record) {
      let document = this.dataSource.find((item) => item.id === record.id);
      if (document) {
        this.update({...document, 'is_obligatoire': Number(e.target.checked)})
                .then(() => this.fetchData());
      }
    },
    isChecked(isRequired) {
      return !!isRequired;
    },
    startProcessing() {
      this.processing = true;
    },
    stopProcessing() {
      this.processing = false;
    },
    searchDossierRequi(e) {
      this.search(e);
    },
    onCellChange(id, dataIndex, $event) {
      let document = this.dataSource.find((item) => item.id === id);
      if (document) {
        document.nom_fr = $event;
        this.startProcessing();
        this.update(document)
                .then(() => this.fetchData());
      }
    },
    onDelete(attestation) {
      this.remove(attestation)
              .then(() => this.fetchData());
    },
    handleAdd() {
      this.add({nom_fr: `Saisissez un document`})
              .then(() => this.fetchData());
    },
    ...mapActions({
      fetchDossiersRequis: "fetchDossiersRequis",
      update: "updateDossierRequi",
      remove: "destroyDossierRequi",
      add: "storeDossierRequi",
      search: "filteredListOfDossiersRequis",
    }),
  },
};
</script>
<style>
.table_head {
  margin-bottom: 2%;
}
.editable-cell:hover .editable-cell-icon {
  display: inline-block;
}

.editable-add-btn {
  margin-bottom: 8px;
}
.text-end {
  text-align: end;
}
</style>