var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "h1",
        { staticStyle: { "margin-left": "4%", "margin-bottom": "4%" } },
        [_vm._v("Dossier requis")]
      ),
      _c(
        "a-row",
        {
          staticClass: "table_head",
          attrs: { type: "flex", justify: "center" }
        },
        [
          _c(
            "a-col",
            { attrs: { span: 8 } },
            [
              _c(
                "a-button",
                {
                  staticClass: "editable-add-btn",
                  attrs: { type: "primary" },
                  on: { click: _vm.handleAdd }
                },
                [_vm._v("Nouveau document")]
              )
            ],
            1
          ),
          _c(
            "a-col",
            { staticClass: "text-end", attrs: { span: 6 } },
            [
              _c("search-component", {
                on: {
                  change: function($event) {
                    return _vm.searchDossierRequi($event)
                  }
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-row",
        { attrs: { type: "flex", justify: "center" } },
        [
          _c(
            "a-col",
            { attrs: { span: 14 } },
            [
              _c(
                "a-spin",
                { attrs: { spinning: _vm.processing } },
                [
                  _vm.dataSource.length
                    ? _c("a-table", {
                        attrs: {
                          bordered: "",
                          dataSource: _vm.dataSource,
                          columns: _vm.columns,
                          rowKey: function(record) {
                            return record.id
                          }
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "row_index",
                              fn: function(text, record, index) {
                                return [_c("p", [_vm._v(_vm._s(index + 1))])]
                              }
                            },
                            {
                              key: "nom_fr",
                              fn: function(text, record) {
                                return [
                                  _c("editable-cell", {
                                    attrs: { text: text },
                                    on: {
                                      change: function($event) {
                                        return _vm.onCellChange(
                                          record.id,
                                          "nom_fr",
                                          $event
                                        )
                                      }
                                    }
                                  })
                                ]
                              }
                            },
                            {
                              key: "isRequired",
                              fn: function(text, record) {
                                return [
                                  _c(
                                    "a-checkbox",
                                    {
                                      attrs: {
                                        checked: _vm.isChecked(
                                          record.is_obligatoire
                                        )
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.onRequiredChange(
                                            $event,
                                            record
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          record.is_obligatoire ? "Oui" : "Non"
                                        )
                                      )
                                    ]
                                  )
                                ]
                              }
                            },
                            {
                              key: "operation",
                              fn: function(text, record) {
                                return [
                                  _vm.dataSource.length
                                    ? _c(
                                        "a-popconfirm",
                                        {
                                          attrs: { title: _vm.SUPPRIMER_LIGNE },
                                          on: {
                                            confirm: function() {
                                              return _vm.onDelete(record)
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "a-tooltip",
                                            { attrs: { placement: "top" } },
                                            [
                                              _c(
                                                "template",
                                                { slot: "title" },
                                                [
                                                  _c("span", [
                                                    _vm._v("Suppression")
                                                  ])
                                                ]
                                              ),
                                              _c("a-icon", {
                                                staticClass: "action_icons",
                                                attrs: {
                                                  type: "delete",
                                                  theme: "twoTone",
                                                  twoToneColor: "#eb2f96"
                                                }
                                              })
                                            ],
                                            2
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ]
                              }
                            },
                            {
                              key: "etat",
                              fn: function(text, record) {
                                return [
                                  _c("a-icon", {
                                    attrs: {
                                      type: "check-circle",
                                      theme: "twoTone",
                                      twoToneColor: "#52c41a"
                                    }
                                  })
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          2709273072
                        )
                      })
                    : _c("EmptyResult")
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }