import { render, staticRenderFns } from "./DossiersRequisLists.vue?vue&type=template&id=2b4d0b35&"
import script from "./DossiersRequisLists.vue?vue&type=script&lang=js&"
export * from "./DossiersRequisLists.vue?vue&type=script&lang=js&"
import style0 from "./DossiersRequisLists.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/usr/share/nginx/testhtml/front/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2b4d0b35')) {
      api.createRecord('2b4d0b35', component.options)
    } else {
      api.reload('2b4d0b35', component.options)
    }
    module.hot.accept("./DossiersRequisLists.vue?vue&type=template&id=2b4d0b35&", function () {
      api.rerender('2b4d0b35', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/admin/dossiers_requis/DossiersRequisLists.vue"
export default component.exports